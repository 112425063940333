import { LayoutFive } from "../layouts";
import {
  ProductSliderEightWrapper,
  ProductSliderNine,
  DealProductSlider,
  HeroSliderFive,
  Category,
} from "../components";

import { defaultAxios } from "../utils";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import { useEffect } from "react";
import { useRouter } from "next/router";

const HomePage = ({ vendorProducts, prelovedProducts, dashboardData }) => {
  const router = useRouter();

  useEffect(() => {
    if (router && router.isReady) {
      const storeRedirect = router.query?.storeRedirect ?? null;

      // redicrect mobile user to the related store.
      if (storeRedirect) {
        if (Boolean(navigator.userAgent.match(/Android/i)))
          window.location = "https://play.google.com/store/apps/details?id=com.pcari.pcari_user";
        if (Boolean(navigator.userAgent.match(/iPhone|iPad|iPod/i)))
          window.location = "https://apps.apple.com/my/app/pcari-my/id1536313176";
      }
    }
  }, [router]);

  useEffect(() => {
    console.clear();
    console.log(HomePage);
  }, []);
  const { t } = useTranslation("common");
  let currentPage = 1;

  return (
    <LayoutFive navPositionClass="justify-content-start" showCategories={false}>
      {/* hero slider */}
      <HeroSliderFive dashboardData={dashboardData} />
      {/* tab product */}
      <Category />
      {dashboardData.best_seller != null && (
        <>
          {dashboardData.best_seller.length > 0 && (
            <ProductSliderNine
              title={t("Best Selling Products")}
              bannerImage="/assets/images/banner/banner_demo/5.png"
              vendor="best-seller"
              products={dashboardData.best_seller}
            />
          )}
        </>
      )}
      {/*Vendor*/}
      {/* <ProductSliderNine
        title="Recommended Products"
        bannerImage="/assets/images/banner/banner_demo/5.png"
        vendor
        products={vendorProducts}
      /> */}
      {/* End vendor*/}
      {/* Test preloved*/}
      {process.env.NEXT_PUBLIC_ENV !== "production" && (
        <>
          {prelovedProducts.length > 0 && (
            <ProductSliderNine
              title={t("Explore Preloved Items")}
              bannerImage="/assets/images/banner/banner_demo/5.png"
              vendor="preloved"
              products={prelovedProducts}
            />
          )}
        </>
      )}
      {/* End preloved*/}
      {dashboardData.flash_sales != null && (
        <>
          {dashboardData.flash_sales.length > 0 && (
            <DealProductSlider title="Flash Sale" products={dashboardData.flash_sales} />
          )}
        </>
      )}
      {/* <ProductTabThree
        title="Exclusive Products"
        bannerImage="/assets/images/banner/banner_demo/4.jpg"
        newProducts={vendorProducts}
        bestSellerProducts={vendorProducts}
        featuredProducts={vendorProducts}
        saleProducts={vendorProducts}
      /> */}
      <ProductSliderEightWrapper
        title={t("All Products")}
        products={vendorProducts}
        currentPage={1}
      />
    </LayoutFive>
  );
};

export const getStaticProps = async ({ locale }) => {
  let vendorProducts = [],
    prelovedProducts = [];
  let dashboardData = {};
  let currentPage = 1;
  try {
    vendorProducts = (await defaultAxios.get("product?page=" + currentPage)).data.data.data ?? [];
    prelovedProducts = (await defaultAxios.get("preloved")).data.data.data ?? [];
    prelovedProducts.forEach((prod) => (prod.preloved = true));
    dashboardData = (await defaultAxios.get("dashboard")).data ?? {};
  } catch (err) {
    console.log(err);
    vendorProducts = [];
    prelovedProducts = [];
    dashboardData = {};
  }

  return {
    props: {
      // vendor product
      revalidate: 3600 * 6,
      vendorProducts,
      prelovedProducts,
      dashboardData,
      ...(await serverSideTranslations(locale, ["common"])),
    }, // will be passed to the page component as props
    // revalidate: 3600 * 6
  };
};

export default HomePage;
